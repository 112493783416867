a {
    text-decoration: none;
}

.legend-img {
    height: 15px;
    width: 15px;
}

p {
    line-height: revert !important;
}