.navbar-container{
    background-color:#A5D3DE;
    line-height: 4;
    word-break: break-word;
    overflow-wrap: break-word;
    position: relative;
    height: calc(100% - 10px);
}

.abajo{
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0
}

.img-margin{
    margin-left: 20px;
    margin-right: 30px;
}

p {
    line-height:10px;
}

.navbar-title{
    line-height: 1.4;
    text-align: left;
    word-break: break-word;
    overflow-wrap: break-word;
}

.b{
    font-size: large;
    font-weight: 750;
}

.nav-link{
    font-size: 15px;
    line-height: 1.4;
}

@media screen and (max-width: 790px) {
    .navbar-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-link {
        font-size: 10px;
    }

    #responsive-navbar-nav {
        font-size: 10px;
    }

    .navbar-title {
        text-align: center;
        font-size: 12px;
    }

    .b {
        font-size: 15px;
    }
}
