.bg-h{
    background: #0700A8;
}

.col{
    display: flex;
    flex-direction: column;
}

.marker-title {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    text-align: center;
}

.button {
    float: right;
    background-color: #8e8da7;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #fff;
    border-radius: 18px;
    margin: auto;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    display:inline-block;
    outline: none;
}
.button:not(.open):hover {
    background: #8686a0;
    transform: scale(1.08);
}
