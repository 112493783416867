.flex-start {
    -webkit-align-content: flex-start; 
    align-content: flex-start;
}

.flex-end { 
    -webkit-align-content: flex-end; 
    align-content: flex-end; 
}

a {
    text-decoration: none;
}

#no-dec{
    color: #000000;
}
#no-dec:hover,active,visited{
    color: #000000;
    text-decoration: none;
}

.container-right{
    flex-wrap: wrap;
    display: flex;
    align-self: flex-end;
    justify-self: right;
    justify-content: flex-end;
    float: right;
    clear: left;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 40px;
    background-color: #F0F0F0;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #FFF;
}

::-webkit-scrollbar-thumb {
    border-radius: 40px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #B5B5B5;
}


.pin-container {
    overflow-y: scroll;
    position: relative;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    padding: 0;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    width: auto;
    height: 500px;
    margin-top: 0;
}
    .pin-container-title {
        font-size: 28px;
        font-weight: 600;
        color: #000000;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 35px;
        margin-right: 8%;
        margin-left: 8%;
        overflow-wrap: break-word;
        word-break: break-word;
    }
    .pin-container-title:hover{
        transform: scale(1.02);
        text-shadow: 1px 1px 2px lightgrey;
    }
    .pin-container-text {
        font-size: 16px;
        color: #383838;
        line-height: 1.5em;
        word-wrap: break-word;
        word-break: break-word;
        margin-bottom: 30px;
        text-align: center;
    }
    .pin-container-text-title{
        font-size: 16px;
        color: #E05334;
        font-weight: bold;
        overflow-wrap: break-word;
        word-break: break-word;
        text-align: center;
    }

.middle-container{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top:25px;
    margin-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
}

.coordinates {
    color: #383838;
    text-align: center;
    font-size:15px;
    position: relative;
    padding: 15px;
}

.authors-container{
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top:30px;
    margin-bottom: 18px;
    margin-right: auto;
    margin-left: auto;
    box-shadow: -8px 10px 3px -11px rgba(0, 0, 0, 0.7);
}
    .authors-title{
        font-size: 22px;
        color: #383838;
        font-weight: bold;
        padding-left: 8px;
        padding-right: 5px;
        overflow-wrap: break-word;
        word-break: break-word;
    }
    .authors-img > img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        padding-bottom: 10px;
        padding-left: 5px;
    }
    .authors-list {
        width: 55%;
        margin: auto;
        margin-bottom: 45px;
    }
    .author {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        align-items: center;
    }
    .author-img > img {
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        padding-left: 20px;
        box-sizing: content-box;
    }
    .author-name {
        color: #383838;
        font-weight: 500;
        font-size: 14px;
        margin-left: 10px;
        overflow-wrap: break-word;
        word-break: break-word;
    }

.tags-container {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    float: left;
    font-size: 10px;
}
    .tag {
        background-color: #D1D1D1;
        border-color: #D1D1D1;
        border-radius: 10px;
        border-style: solid;
        border-width: 1.8pt;
        padding: 6px;
        margin: 2px;
        color: #333;
        margin-top: 10px;
        transition: 0.3s;
        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;
        display:inline-block;
        overflow-wrap: break-word;
        word-break: break-word;
    }
    .tag:not(.open):hover {
        transform: scale(1.08);
    }

        .tag-title{
            font-size: 16px;
            color: #000;
            font-weight: bold;
            overflow-wrap: break-word;
            word-break: break-word;
        }

.content-button {
    float: right;
    background-color: #1BB0D1;
    border: none;
    padding: 8px 26px;
    color: #fff;
    border-radius: 18px;
    margin-top: 10px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    display:inline-block;
    overflow-wrap: break-word;
    word-break: break-word;
}
    .content-button:not(.open):hover {
        background: #199FBD;
        transform: scale(1.08);
    }