.radio-toggle {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}

.radio-toggle button {
    background-color: rgba(255,255,255,1);
    cursor: pointer;
    border: none;
    border-right: 1px solid;
    padding: 8px 10px;
    border-color: darkgray;
}

.radio-toggle button:last-child {
    border-right: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.radio-toggle button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.choice-label{
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    width: 50%;
    z-index: 2;
    margin-bottom: 0rem;
}

.slide-item {
    background: white;
    border-radius: 9999px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.15);
    height: calc(100% - 8px);
    left: calc(3%);
    position: absolute;
    width: calc(50% - 5px);
    transition: left .4s;
    z-index: 1;
}

input[type="radio"] {
    left: -9999px;
    position: absolute;
    z-index: -1;
}

input[type="radio"]:nth-of-type(1):checked ~ .slide-item {
    left: 5px;
    width: calc(50%);
}
input[type="radio"]:nth-of-type(2):checked ~ .slide-item {
    left: calc(50%);
    width: calc(50% - 5px);
}

.info-toggles {
    align-items: center;
    background: #eee;
    border: 1px solid grey;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    max-width: 400px;
    overflow: hidden;
    padding: 4px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 1;
}


.button_info {
    background-color: #8dc1cb;
    width: 45px;
    height: 45px;
    border: none;
    color:#ffffff;
    border-radius: 50%;
    margin: auto;
    font-weight: 600;
    font-size: 1.65em;
    padding: 0;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    display:inline-block;
    outline: none;
}

.button_info:not(.open):hover {
    transform: scale(1.08);
}

@media screen and (max-width: 790px) {
    .radio-toggle button {
        font-size: 10px;
        font-weight: 500;
    }

    .info-toggles button {
        font-size: 10px;
        font-weight: 500;
        width: 25px;
        height: 25px;
    }
}
