@import '~leaflet/dist/leaflet.css'; // sass
@import '~react-leaflet-markercluster/dist/styles.min.css';

html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100vh;
}

#scorwg-navbar {
    height: 100px;
    position: relative;
}

#scorwg-map {
    height: calc(100% - 130px);
    position: relative;
}

#scorwg-footer {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#scorwg-footer p {
  font-size: small;
}

@media screen and (max-width: 790px) {
  #scorwg-navbar {
      height: 180px;
  }

  #scorwg-map {
      height: calc(100% - 210px);
  }

  #scorwg-footer p {
    font-size: 10px;
  }
}

@media screen and (max-width: 448px) {
  #scorwg-map {
      height: calc(100% - 225px);
  }

  #scorwg-footer {
    height: 60px;
  }
}

@media screen and (max-width: 386px) {
  #scorwg-navbar {
    height: 200px;
  }

  #scorwg-map {
    height: calc(100% - 245px);
  }
}

@media screen and (max-width: 289px) {
  #scorwg-navbar {
    height: 210px;
  }

  #scorwg-map {
    height: calc(100% - 255px);
  }  
}
